import { render, staticRenderFns } from "./AgendasManage.vue?vue&type=template&id=6df06b12&"
import script from "./AgendasManage.vue?vue&type=script&lang=js&"
export * from "./AgendasManage.vue?vue&type=script&lang=js&"
import style0 from "@/assets/styles/themes/default/agendas.css?vue&type=style&index=0&lang=css&"
import style1 from "../assets/scss/Agendas.scss?vue&type=style&index=1&lang=scss&"
import style2 from "./AgendasManage.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports